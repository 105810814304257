<div class="flex flex-col p-6 relative" *transloco="let t">
  <div (click)="close()" class="h-6 w-6 absolute right-3 top-2 cursor-pointer">
    <img src="https://cdn.chipychapa.com//welle/General_site/Welle_Stocks/close_modal.svg" alt="close" />
  </div>
  <div class="flex flex-col pb-4 font-bold gap-2">
    <span class="text-2xl">
      {{ t(openModal === 'withdrawal' ? 'bank.withdrawal.to_account' : 'bank.deposit.start_earning') }}
    </span>
    <span class="text-xl text-[#146290] font-semibold">
      {{ t(openModal === 'withdrawal' ? 'bank.deposit.current_balance' : 'bank.deposit.current_balance') }}
    </span>
    <span class="text-[1.38rem] font-semibold px-2">
      {{ currency | userCurrencyPipe }} {{ bankAmount?.toFixed(2) }}
    </span>
  </div>
  <div class="flex flex-col gap-3">
    <span *ngIf="openModal === 'withdrawal'" class="text-lg text-[#146290] font-semibold">
      {{ t('bank.withdrawal.amount') }}
    </span>

    <div
      [class]="
        'flex flex-row gap-1 bg-white rounded-[0.63rem] py-1 px-[0.94rem] h-[3.75rem]  items-center text-[1.38rem] font-semibold ' +
        (!isValid && inputAmount !== 0 && !isLoading ? 'text-errorColor border-2  border-errorColor' : 'text-grayText')
      ">
      <span>{{ userBalance.currency | userCurrencyPipe }}</span>
      <input
        class="w-full h-full placeholder:text-grayText hover:placeholder:opacity-50 focus:placeholder:opacity-0 placeholder:transition-all placeholder:ease-in-out placeholder:duration-300 !text-[1.38rem] font-semibold"
        placeholder="0"
        maxlength="8"
        (focus)="clearInput($event)"
        (input)="onInput($event.target.value, $event)"
        [value]="inputAmount" />
    </div>
    @if (!isValid && inputAmount !== 0 && !isLoading) {
      <div class="flex gap-1">
        <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/error_field.svg" alt="error" />
        <span class="text-errorColor font-normal">{{ t('bank.withdrawal.to_account_invalid') }}</span>
      </div>
    }
    @if (openModal === 'withdrawal') {
      <div
        [class]="
          'justify-between flex flex-col sm:flex-row-reverse gap-4 ' + (isLoading ? ' pointer-events-none ' : ' ')
        ">
        <div
          (click)="handleTransaction(true)"
          [class]="
            'h-[3.75rem] rounded-[0.63rem] cursor-pointer bg-mainBtnLight hover:bg-mainHoverLight flex-center text-lg font-semibold w-full sm:w-[14rem] ' +
            (!isValid ? ' pointer-events-none ' : ' ')
          ">
          <span *ngIf="!isLoading; else isLoadingTemplate">{{ t('bank.deposit.withdrawal') }}</span>
        </div>
        <div
          (click)="handleTransaction(true, true)"
          class="h-[3.75rem] cursor-pointer flex-center text-lg font-semibold w-full sm:w-[14rem]">
          <span *ngIf="!isLoading; else isLoadingTemplate">{{ t('bank.withdrawal.all') }}</span>
        </div>
      </div>
    } @else {
      <div>
        <button
          [class]="
            'group relative flex gap-1 justify-center items-center  h-[3.75rem]  text-mainTextLight w-full p-3 rounded-[0.63rem] cursor-pointer font-semibold text-sm bg-mainBtnLight hover:bg-mainHoverLight ' +
            (isMobile ? ' z-[2] ' : ' ') +
            (isLoading || !isValid ? ' pointer-events-none ' : ' ')
          "
          (click)="handleTransaction(false)">
          <div
            *ngIf="!isLoading; else isLoadingTemplate"
            class="flex items-center justify-center text-lg font-semibold">
            <span>{{ t('bank.deposit.add_money') }}</span>
          </div>
        </button>
        <div
          *ngIf="isMobile"
          class="w-full bg-darkBlueBgLight rounded-b-lg flex flex-col justify-center p-2 pt-3 -mt-2 z-[1]">
          <div class="flex flex-col justify-center text-white">
            <div class="font-medium flex-center">
              min.
              <span class="font-bold px-1">
                {{ userBalance.currency | userCurrencyPipe }}
                {{ bankrates?.userConditionsRelation?.limitsRelation?.gte | turnoverSumFormatter }}
              </span>
              - max.
              <span class="font-bold px-1">
                {{ userBalance.currency | userCurrencyPipe }}
                {{ bankrates?.userConditionsRelation?.limitsRelation?.lte | turnoverSumFormatter }}
              </span>
            </div>
            <div class="text-xs gap-0.5 flex text-center justify-center flex-col">
              <span>{{ t('bank.deposit.maximum_depends_on_rank') }}</span>
              <span class="font-bold">{{ t(bankrates?.rank || 'loyalty.ranks.without_rank') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="relative flex-center" *ngIf="!isMobile">
        <div class="font-medium flex-center">
          min.
          <span class="font-bold px-1">
            {{ userBalance.currency | userCurrencyPipe }}
            {{ bankrates?.userConditionsRelation?.limitsRelation?.gte | turnoverSumFormatter }}
          </span>
          - max.
          <span class="font-bold px-1">
            {{ userBalance.currency | userCurrencyPipe }}
            {{ bankrates?.userConditionsRelation?.limitsRelation?.lte | turnoverSumFormatter }}
          </span>
        </div>
        <div class="h-5 w-5 cursor-pointer group absolute top-1 right-6">
          <img
            width="20"
            height="20"
            src="https://cdn.chipychapa.com//welle/General_site/Welle_Stocks/info.svg"
            alt="info" />
          <div
            class="drawer hidden absolute group-hover:block text-mainText py-2 px-3 bg-tooltipLight rounded-xl bottom-6 text-center left-1/2 transform -translate-x-1/2 text-xs font-medium md:min-w-28 z-[3]">
            <div class="text-xs">
              <span>
                {{ t('bank.deposit.maximum_depends_on_rank') }}
                <span class="font-bold">{{ t(bankrates?.rank || 'loyalty.ranks.without_rank') }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
<ng-template #isLoadingTemplate>
  <div [class]="'w-6 h-6 mx-auto animate-spin rounded-full overflow-hidden'">
    <img
      src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/loader.png"
      alt="progress loader" />
  </div>
</ng-template>
